import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, { useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"
import { PrevButton, NextButton } from "../../UI/Slider/SliderArrows"

import { Section, SectionHeading, Container, TextBlock } from "../../UI/Common"

import BrandItem from "./BrandItem"
import { infiniteScrollSettings } from "../../UI/Slider/Slide"

const BrandsCarouselVariant2 = ({ sectionHeading, text, brands, config }) => {
  const { padding } = config || {}
  const [slide, setSlide] = useState(0)
  const slider = useRef(null)

  return (
    <Section padding={padding}>
      <>
        <Container>
          <>
            <SectionHeading text={sectionHeading} alignment="center" />
            <TextBlock text={text} alignment="center" mode="muted" />
          </>
        </Container>
        <div className="position-relative">
          <div>
            <PrevButton ref={slider} show={[slide, setSlide]} />
          </div>
          <div>
            <NextButton ref={slider} show={[slide, setSlide]} />
          </div>
          <div className="position-relative">
            <Slider ref={slider} {...infiniteScrollSettings}>
              {brands &&
                brands.map(item => {
                  const brand = item.brand ? item.brand : item
                  return <BrandItem brand={brand} key={uuidv4()} />
                })}
            </Slider>
          </div>
        </div>
      </>
    </Section>
  )
}
export default BrandsCarouselVariant2
