import { graphql } from "gatsby"
import React from "react"
import BrandsCarouselVariant2 from "./BrandsCarouselVariant2"

export const fragment = graphql`
  fragment BrandsCarouselVariant2Fragment on WpPage_Flexlayouts_FlexibleLayouts_BrandsCarouselVariant2 {
    sectionHeading
    text
    brands {
      brand {
        name
        id
        slug
        extendedBrandInfo {
          logo {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
          smallImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFBrandsCarouselVariant2 = ({ sectionHeading, text, brands, config }) => {
  return (
    <BrandsCarouselVariant2
      sectionHeading={sectionHeading}
      text={text}
      brands={brands}
      config={config}
    />
  )
}
