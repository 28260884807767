// extracted by mini-css-extract-plugin
export var bgGrey100 = "BrandItem-module--bg-grey-100--4cc84";
export var bgGrey150 = "BrandItem-module--bg-grey-150--ed331";
export var bgGrey200 = "BrandItem-module--bg-grey-200--119e9";
export var bgGrey300 = "BrandItem-module--bg-grey-300--63fa0";
export var bgGrey400 = "BrandItem-module--bg-grey-400--ef343";
export var bgGrey500 = "BrandItem-module--bg-grey-500--d5eae";
export var bgGrey600 = "BrandItem-module--bg-grey-600--1c01b";
export var bgGrey700 = "BrandItem-module--bg-grey-700--27754";
export var bgGrey800 = "BrandItem-module--bg-grey-800--d8a0c";
export var bgGrey900 = "BrandItem-module--bg-grey-900--4c1be";
export var boxCls = "BrandItem-module--boxCls--e5f82";
export var logoCls = "BrandItem-module--logoCls--9440c";
export var productImgCls = "BrandItem-module--productImgCls--8cfab";
export var productNameCls = "BrandItem-module--productNameCls--8033d";
export var textGrey100 = "BrandItem-module--text-grey-100--e41b0";
export var textGrey150 = "BrandItem-module--text-grey-150--b0b79";
export var textGrey200 = "BrandItem-module--text-grey-200--1e59e";
export var textGrey300 = "BrandItem-module--text-grey-300--47f49";
export var textGrey400 = "BrandItem-module--text-grey-400--db5bb";
export var textGrey500 = "BrandItem-module--text-grey-500--912eb";
export var textGrey600 = "BrandItem-module--text-grey-600--a7025";
export var textGrey700 = "BrandItem-module--text-grey-700--b339f";
export var textGrey800 = "BrandItem-module--text-grey-800--39b1a";
export var textGrey900 = "BrandItem-module--text-grey-900--e4d9c";