import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Link } from "../../UI/Common"

import {
  boxCls,
  logoCls,
  productImgCls,
  productNameCls
} from "./BrandItem.module.scss"

const BrandItem = ({ brand }) => {
  if (!brand) {
    console.warn("No brand data.")
    return null
  }

  const {
    slug,
    name,
    extendedBrandInfo: { logo, smallImage } = { logo: null, smallImage: null },
  } = brand

  return (
    <Link link={`/brands/${slug}`}>
      <div className={boxCls} key={uuidv4()}>
        {smallImage && (
          <GatsbyImage
            className={productImgCls}
            image={getImage(smallImage.localFile)}
            alt={smallImage.altText}
            loading="eager"
          />
        )}
        {logo && (
          <GatsbyImage
            style={{ position: "absolute" }}
            className={logoCls}
            image={getImage(logo.localFile)}
            alt={logo.altText}
            loading="eager"
          />
        )}
      </div>
      <p className={`${productNameCls} text-center`}>{name}</p>
    </Link>
  )
}

export default BrandItem
